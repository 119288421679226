import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Learnsbagrants from './components/Learnsbagrants';
import Contact from './components/Contact';
import About from './components/About';


function App() {
  return (
    <Routes>
      <Route path='/'  element={<Home/>}/>
      <Route path='/Navbar' element={<Navbar/>}/>
      <Route path="/Learnsbagrants" element={<Learnsbagrants/>}/>
      <Route path='/Contact' element={<Contact/>}/>
      <Route path='/About' element={<About/>}/>
    </Routes>
  );
}

export default App;
