import React from 'react';
import Navbar from './Navbar';
import Footer from './hooks/Footer';

const Learnsbagrants = () => {
  return (
    <>
    <Navbar/>
    <div className='learn_sba'>
        <div className='mx-auto md:max-w-2xl overflow-hidden relative'>
            <div className='bg-geen-600 flex flex-col justify-center items-center h-[70vh]'>
                <div className='text-center'>
                    <p className='text-white font-bold xl:text-[45px] text-[35px]'>Learn about SBA grants</p>
                </div>
            <div className='text-center pt-1'>
                <p className='text-white'>Start or expand your business with grants guaranteed by the Small Business Administration. Use Lender Match to find lenders that offer grants for your business.</p>
            </div>
            </div>
        </div>
    </div>

   <div className='mx-auto overflow-hidden md:max-w-7xl'>
    <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[2%]'>
        <div className='bg-gren-600 h-fit'>
            <div>
                <p className='font-bold xl:text-[34px] text-[25px] text-[#002e6d]'>How SBA helps small businesses get grants</p>
            </div>
            <div className='red__border'></div>
            <div className='pt-5'>
                <p>The U.S. Small Business Administration helps small businesses get funding by setting guidelines for grants and reducing lender risk. These SBA-backed grants make it easier for small businesses to get the funding they need.</p>
            </div>

            <div className='pt-5'>
                <p className='font-semibold'>In order to get an SBA-backed grants:</p>
            </div>

            <div className='pt-5'>
                <div className='flex gap-5'>
                    <span>
                        <i class="ri-git-commit-fill font-extrabold text-[#BF0206]"></i>
                    </span>
                    <p>Explore our Apply page to discover the grants that best suit your needs.</p>
                </div>
            </div>
            <div className='pt-1'>
                <div className='flex gap-5'>
                    <span>
                        <i class="ri-git-commit-fill font-extrabold text-[#BF0206]"></i>
                    </span>
                    <p>Apply for a grant and complete the sign-up process.</p>
                </div>
            </div>
            <div className='pt-1'>
                <div className='flex gap-5'>
                    <span>
                        <i class="ri-git-commit-fill font-extrabold text-[#BF0206]"></i>
                    </span>
                    <p>Monitor the progress of your grant application.</p>
                </div>
            </div>
            <div className='pt-1'>
                <div className='flex gap-5'>
                    <span>
                        <i class="ri-git-commit-fill font-extrabold text-[#BF0206]"></i>
                    </span>
                    <p>Upload all necessary photos to successfully secure your grants.</p>
                </div>
            </div>
        </div>
        <div className='bg-green-600 h-fit'>
            <div>
                <img src="https://upload.wikimedia.org/wikipedia/commons/7/7b/FEMA_-_30747_-_SBA_worker_shakes_hands_with_FCO_in_South_Dakota.jpg" alt="" />
            </div>
        </div>
    </div>
   </div>

   <div className='mx-auto overflow-hidden md:max-w-7xl pt-5'>
        <p className='text-2xl font-extrabold text-center xl:text-[34px] text-[24px] text-[#002e6d]'>
            Grants for borrowers
        </p>


        <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 md:grid-cols-3 gap-5 pb-10 pt-[2%]'>
          <div className='bg--600 h-full border_b'>
            <div>
              <img src="https://www.sba.gov/sites/default/files/styles/card/public/2021-01/GettyImages-1210182395.jpg.webp?itok=yOguV2gD" alt="" />
            </div>

            <div className='p-7'>
            <div>
              <p className='font-semibold text-[#224980]'>7(a) grants</p>
            </div>

            <div className='pt-3'>
              <p>A group of SBA grants which guarantee portions of the total amount, cap interest rates, and limit fees.</p>
            </div>
            </div>
          </div>
          <div className='bg--600 h-fit border_b'>
            <div>
              <img src="https://www.sba.gov/sites/default/files/styles/card/public/2021-01/GettyImages-1145684652.jpg.webp?itok=eAu5Tndw" alt="" />
            </div>

            <div className='p-7'>
            <div>
              <p className='font-semibold text-[#224980]'>504 Grants</p>
            </div>

            <div className='pt-3'>
              <p>Long-term, fixed-rate financing to purchase or repair real estate, equipment, machinery, or other assets.</p>
            </div>
            </div>
          </div>
          <div className='bg--600 h-full border_b'>
            <div>
              <img src="https://www.sba.gov/sites/default/files/styles/card/public/2021-01/GettyImages-1037487390.jpg.webp?itok=ryIf9qcK" alt="" />
            </div>

            <div className='p-7'>
            <div>
              <p className='font-semibold text-[#224980]'>MicroGrants</p>
            </div>

            <div className='pt-3'>
              <p>Our smallest Grants program, providing $50,000 or less to help businesses start up and expand.</p>
            </div>
            </div>
          </div>
          
        </div>


        <div className='pt-5 ml-3'>
            <p className='font-bold xl:text-[34px] text-[24px] text-[#002e6d]'>Benefits of SBA-guaranteed grants.</p>
        </div>

        <div className='bg-gren-600 h-fit ml-3'>
            
            <div className='red__border'></div>
            

            

            <div className='pt-5'>
                <div className='flex gap-5'>
                    <span>
                        <i class="ri-git-commit-fill font-extrabold text-[#BF0206]"></i>
                    </span>
                    <p>
                        <span className='font-bold'>Counseling and education :</span> Some Grants come with continued support to help you start and run your business.
                    </p>
                </div>
            </div>
            <div className='pt-1'>
                <div className='flex gap-5'>
                    <span>
                        <i class="ri-git-commit-fill font-extrabold text-[#BF0206]"></i>
                    </span>
                    <p>
                        <span className='font-bold'>Unique benefits :</span> Lower down payments, flexible overhead requirements, and no collateral needed for some grants.
                    </p>
                </div>
            </div>
            <div className='pt-1'>
                <div className='flex gap-5'>
                    <div className='flex gap-5'>
                    <span>
                        <i class="ri-git-commit-fill font-extrabold text-[#BF0206]"></i>
                    </span>
                    <p>
                        <span className='font-bold'>Special Perks :</span> Enjoy advantages such as lower initial costs, adaptable overhead requirements, and no collateral requirements for select grants.
                    </p>
                </div>
                </div>
            </div>
        </div>
   </div>

    <div className='mx-auto md:max-w-7xl overflow-hidden bg-[#002e6d] mt-10 h-fit mb-10'>
        <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 py-5'>
            <div className='bg-[#002e6d] h-fit'>
                <div className='pl-5 pt-5'>
                    <p className='font-bold text-white text-2xl'>Need help? Get free business counseling</p>
                </div>
            </div>
            <div className='bg-[#002e6d] h-fit'>
               <div className='xl:flex xl:justify-center block items-start ml-5'>
                    <a href="/Contact">
                        <div className='  pt-4'>
                            <button className='bg-[#017dbc] p-3  text-white rounded-md font-semibold cursor-pointer w-[150px]  make_btn'>Contact us</button>
                        </div>
                    </a>
               </div>
            </div>
        </div>
    </div>
    <Footer/>
    </>
  )
}

export default Learnsbagrants