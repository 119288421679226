import React, { useState } from "react";
import { RiMenuLine } from "react-icons/ri";
import { FiX } from "react-icons/fi";
import { Link } from "react-router-dom";


const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const [isServicesOpen, setIsServicesOpen] = useState(false);
 

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

//   const toggleServices = () => {
//     setIsServicesOpen(!isServicesOpen);
//   };
 
  return (
    <nav className="bg-[#ffffff] z-40 fixed w-full p-2  shadow-md">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
            <div className="p-1">
               <Link to='/'>
                    <img src="https://sbaexpressgrants.org/wp-content/uploads/2023/12/cropped-SBA-Logo-H-300x84.png" alt="" className="w-[50%] xl:w-[50%]" />
               </Link>
            </div>
        </div>
        <button className="text-white ml-auto md:hidden" onClick={toggleSidebar}>
                {isSidebarOpen ? <FiX size={24} className="!text-[black]" /> : <RiMenuLine size={24} className="!text-[#4285f4]" />}
        </button>


        <ul className="hidden md:flex space-x-4 mr-3">
          <Link to="/"><li className="text-[black]">Home</li></Link>
          <div className="relative inline-block text-left items-center justify-center">
            <div className="flex">
              {/* <button
                onClick={toggleServices}
                className="text-[black]"
              >
                Services
              </button> <i class="ri-arrow-down-s-line text-[#4285f4] cursor-pointer" onClick={toggleServices}></i> */}
            </div>
            {/* {isServicesOpen && (
              <div className="absolute z-10 mt-2 bg-white border border-gray-200 rounded-md shadow-lg w-[20rem]">
                <div className="flex justify-end">
                <i class="ri-close-line text-black pb- cursor-pointer text-[1.3rem]" onClick={toggleServices}></i>
                </div>
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu ">
                  <Link to="/Blackmail" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Blackmail/Extortion
                  </Link>
                  <Link to="/CryptoRecovery" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Crypto Recovery
                  </Link>
                  <Link to="/AccountUnlock" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Account Activation
                  </Link>
                  <Link to="/RomaceScamRecovery" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Romance Scam Recovery
                  </Link>
                  <Link to="/SocialMediaRecovery" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Social Media Hack/Recovery
                  </Link>
                  <Link to="/DeviceUnlock" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Phone/Device Unlock
                  </Link>
                </div>
              </div>
            )} */}
          </div>
          <Link to="/About"><li className="text-[black]">About</li></Link>
          <a href="#Review"><li className="text-[black]">Reviews</li></a>
          <a href="https://sbamoneygrants.org/apply/now/" target="_blank" rel="noreferrer">
            <li className="text-[black]">Apply</li>
          </a>
          <a href="/contact"><li className="text-[black]">Contact</li></a>
          <a href="https://user.sbamoneygrants.org/login" target="_blank"  rel="noreferrer">Login</a>
        </ul>
      </div>

      {/* Dark Background Overlay */}
      <div
        className={`${
          isSidebarOpen ? "block" : "hidden"
        } md:hidden fixed top-0 left-0 h-screen w-full bg-opacity-70 bg-black`}
        onClick={toggleSidebar}
      ></div>

      {/* Sidebar */}
      <div
        className={`md:hidden fixed z-40 top-0 right-0 h-screen w-64 bg-[#162e51] transform transition-transform ${
            isSidebarOpen ? "translate-y-0 " : "translate-x-full "
          }`}
      >
        <div className="p-4 text-white">
          <h2 className="text-xl font-bold">SBA</h2>
          <ul className="mt-4">
            <Link to="/"><li className="py-2 hover:bg-blue-600" onClick={toggleSidebar}>Home</li></Link>
            {/* <div className="flex">
              <button
                onClick={toggleServices}
                className="text-[white]"
              >
                Services
              </button> <i class="ri-arrow-down-s-line text-[#fefefe] cursor-pointer" onClick={toggleServices}></i>
            </div>
            {isServicesOpen && (
              <div className="absolute z-10 mt-2 bg-white border border-gray-200 rounded-md shadow-lg w-[20rem]">
                <div className="flex justify-end">
                <i class="ri-close-line text-black pb- cursor-pointer text-[1.3rem]" onClick={toggleServices}></i>
                </div>
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu ">
                  <Link to="/Blackmail" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Blackmail/Extortion
                  </Link>
                  <Link to="/CryptoRecovery" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Crypto Recovery
                  </Link>
                  <Link to="/AccountUnlock" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Account Activation
                  </Link>
                  <Link to="/RomaceScamRecovery" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Romance Scam Recovery
                  </Link>
                  <Link to="/SocialMediaRecovery" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Social Media Hack/Recovery
                  </Link>
                  <Link to="/DeviceUnlock" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Phone/Device Unlock
                  </Link>
                </div>
              </div>
            )} */}
            <a href="/About"><li className="py-2 hover:bg-blue-600" onClick={toggleSidebar}>About</li></a>
            <a href="#Review"><li className="py-2 hover:bg-blue-600" onClick={toggleSidebar}>Reviews</li></a>
            <a href="/Contact"><li className="py-2 hover:bg-blue-600" onClick={toggleSidebar}>Contact</li></a>
            <a href="https://sbamoneygrants.org/apply/now/" target="_blank" rel="noreferrer">
              <li className="py-2 hover:bg-blue-600" onClick={toggleSidebar}>Apply</li>
            </a>
            <a href="https://user.sbamoneygrants.org/login" target="_blank" rel="noreferrer">
              <li className="py-2 hover:bg-blue-600" onClick={toggleSidebar}>Login</li>
            </a>
          </ul>
        </div>
        <button
          className="absolute top-2 right-2 text-white"
          onClick={toggleSidebar}
        >
          <FiX size={24} />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;