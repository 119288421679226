import React from 'react';
import Navbar from './Navbar';
import { useForm } from "react-hook-form";
import Footer from "./hooks/Footer";

const Contact = () => {
    
    const {
        register,
        trigger,
      } = useForm();
    
      const onSubmit = async (e) => {
        console.log("~ e", e);
        const isValid = await trigger();
        if (!isValid) {
          e.preventDefault();
          
        }
      };

      const mapStyle = {
        border: 0,
        width: '100%', 
        height: '500px',
      };
    
  return (
    <>
    <Navbar/>
    <div className='Contact_sba'>
        <div className='mx-auto md:max-w-2xl overflow-hidden relative'>
            <div className='bg-geen-600 flex flex-col justify-center items-center h-[70vh]'>
                <div className='text-center'>
                    <p className='text-white font-bold xl:text-[45px] text-[35px]'>Contact SBA</p>
                </div>
            <div className='text-center pt-1'>
                <p className='text-white'>
                Find ways to contact us by phone and email, along with other avenues to guide you through our services.
                </p>
            </div>
            </div>
        </div>
    </div>  

    <div className='bg-gray-100 h-fit'>
        <div className='mx-auto md:max-w-7xl overflow-hidden'>
            <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                <div className='bg-geen-600 h-fit'>
                    <div className="container mx-auto p-4">
                        <form className="max-w-[40rem] mx-auto bg-white p-8 rounded shadow-md"  action="https://formsubmit.co/delighttamarawilliams@gmail.com" method="POST"
                         onSubmit={onSubmit}>
                            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        
                                {/* Full Name */}
                            <div className="mb-4">
                                <label htmlFor="fullName" className="block text-gray-700 text-sm font-bold mb-2">
                                     Full Name
                                </label>
                                     <input
                                        type="text"
                                        id="fullName"
                                        className="w-full p-2 border border-gray-300 rounded"
                                        placeholder="John Doe"
                                        required
                                        {...register("Name", {
                                            required: true,
                                            maxLength: 100,
                                          })}/>
                            </div>
        
                                {/* Email */}
                            <div className="mb-4">
                                    <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        className="w-full p-2 border border-gray-300 rounded"
                                        placeholder="john@example.com"
                                        required
                                        {...register("email", {
                                            required: true,
                                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                          })}
                                    />
                            </div>
        
                                {/* Address */}
                            <div className="mb-4">
                                <label htmlFor="address" className="block text-gray-700 text-sm font-bold mb-2">
                                    Address
                                </label>
                                <input
                                    type="text"
                                    id="address"
                                    className="w-full p-2 border border-gray-300 rounded"
                                    placeholder="123 Main St"
                                    required
                                    {...register("Adress", {
                                        required: true,
                                        maxLength: 200,
                                      })}/>
                            </div>
        
                         {/* Phone Number */}
                            <div className="mb-4">
                                 <label htmlFor="phoneNumber" className="block text-gray-700 text-sm font-bold mb-2">
                                     Phone Number
                                </label>
                                <input
                                type="tel"
                                id="phoneNumber"
                                className="w-full p-2 border border-gray-300 rounded"
                                placeholder="555-555-5555"
                                required
                                {...register("Phone_Number", {
                                    required: true,
                                    maxLength: 200,
                                  })}
                                />
                            </div>
        
                            {/* Message */}
                        <div className="mb-6">
                            <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">
                                 Message
                            </label>
                            <textarea
                                id="message"
                                className="w-full p-2 border border-gray-300 rounded"
                                rows="4"
                                placeholder="Your message..."
                                required
                                {...register("Message", {
                                    required: true,
                                    maxLength: 2000,
                                  })}></textarea>
                        </div>
        
                    {/* Submit Button */}
                     <div className="flex items-center justify-end">
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                            >
                                Submit
                        </button>
                    </div>
                 </form>
                </div>

                </div>
                <div className='bg-reen-600 h-full'>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3105.714996738621!2d-77.0155718!3d38.884762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7b781f29d57d5%3A0x597f97dbf5a763ce!2s409%203rd%20St%20SW%2C%20Washington%2C%20DC%2020024%2C%20USA!5e0!3m2!1sen!2sng!4v1701792097204!5m2!1sen!2sng"
                    style={mapStyle}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Google Maps"
                 ></iframe>
                </div>
            </div>
        </div>
    </div>

    <div>
        <Footer/>
    </div>
    
    </>
  )
}

export default Contact