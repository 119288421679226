import React from 'react';
import Avatar from '@mui/material/Avatar';

const Footer = () => {
  return (
    <footer className='bg-[#c5c5c5] h-fit mt-[0%] p-0'>
          <div className='mx-auto md:max-w-7xl overflow-hidden'>
            <div className='grid mt-10  grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 md:grid-cols-3 gap-5 pb-10 pt-[0%]'>
              <div className='bg-gree-600 h-20'>
                <div className='flex gap-2'>
                <div className='bg-pin-500 h-fit'>
                  <div className='flex justify-center items-center xl:pt-5 pt-0'>
                      <img src="footer-sba.png" alt="" className='xl:w-[70px] w-[80px]'/>
                  </div>
                </div>

                <div className='bg-purpe-500 h-fit'>
                  <div>
                    <p className='font-semibold'>U.S. Small Business Administration</p>
                  </div>
                  <div>
                    <p>409 3rd St., SW</p>
                  </div>
                  <div>
                    <p>Washington, DC 20416</p>
                  </div>
                </div>
                </div>
              </div>
              <div className='bg-gren-600 h-fit'>

              </div>
              <div className='bg-green-00 h-fit pl-5'>
                <div>
                  <p className='font-semibold'>Grant Writer:</p>
                </div>
                  <div className='flex  gap-3 pt-3'>
                    <a href="https://www.facebook.com/profile.php?id=100048567583695&mibextid=dGKdO6" target='_blank' rel="noreferrer" >
                    <Avatar className='!h-[40px] !bg-transparent !w-[40px]' style={{ border: '2px solid #002E6D'}}>
                        <i class="ri-facebook-circle-fill text-[30px] text-[#002E6D]"></i>
                    </Avatar>
                    </a>

                    <a href="https://wa.me/+19206369207" target='_blank' rel="noreferrer">
                      <Avatar className='!h-[40px] !w-[40px]!bg-transparent !w-[40px]' style={{ border: '2px solid #002E6D'}}>
                        <i class="ri-whatsapp-fill text-[28px] text-[#002E6D]"></i>
                      </Avatar>
                    </a>
                  </div>
              </div>

              <div className='bg-green-60 h-fit px-5'>
                  <div>
                    <p className='font-semibold'>Email:</p>
                  </div>

                  <div>
                    <input type="text" placeholder='Enter Your Email' className='w-full p-1 footer_input' />
                  </div>
                  <div>
                    <button className='bg-[#224980] text-white w-full mt-2 rounded-md p-1'>Subcribe</button>
                  </div>
              </div>
            </div>
          </div>
      </footer>
  )
}

export default Footer