import React from 'react';
import Navbar from './Navbar';
import Footer from './hooks/Footer';
import Reviews from "./hooks/Reviews";

const About = () => {
  return (
    <>
    <Navbar/>
    <div className='About_sba'>
        <div className='mx-auto md:max-w-2xl overflow-hidden relative'>
            <div className='bg-geen-600 flex flex-col justify-center items-center h-[70vh]'>
                <div className='text-center'>
                    <p className='text-white font-bold xl:text-[45px] text-[35px]'>About SBA</p>
                </div>
            <div className='text-center pt-1'>
                <p className='text-white'>
                Since its founding, SBA has delivered millions of grants, guarantees, contracts, counseling sessions, and other forms of assistance to small businesses.
                </p>
            </div>
            </div>
        </div>
    </div>  
    <div className='mx-auto overflow-hidden max-w-7xl'>
        <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[3%]'>
            <div className='b-green-600 h-fit'>
                <div className='bg-gren-600 h-full'>
                  <div>
                    <p className='font-bold xl:text-[35px] text-[24px] xl:pt-0 pt-5'>SBA Mission & Vision</p>
                  </div>
                  <div className='red__border'></div>

                  <div className='xl:pt-10 pt-5'>
                    <p>
                    The U.S. Small Business Administration (SBA) helps Americans start, grow, and build resilient businesses.
                    </p> <br />

                    <p>
                        SBA was created in 1953 as an independent agency of the federal government to aid, counsel, assist and protect the interests of small business concerns; preserve free competitive enterprise; and maintain and strengthen the overall economy of our nation. SBA reviews Congressional legislation and testifies on behalf of small businesses. It assesses the impact of regulatory burden on small businesses.
                    </p>

                  </div>
                  <a href="/contact">
                    <div className='pt-10'>
                      <button className='bg-[#017dbc] p-2 text-white rounded-md font-semibold cursor-pointer'>Contact <span>
                      <i class="ri-arrow-right-line"></i>
                        </span></button>
                      </div>
                  </a>
              </div>
            </div>


            <div>
                <img src="https://images.radio.com/aiu-media/sba-desoto-8fc06ebb-d7fc-4c39-acfe-5f218249e3c4.jpg?width=800" alt="" />
            </div>
             
        </div>
    </div>

    <div className='mx-auto overflow-hidden md:max-w-7xl'>
        <div className='ml-5'>
            <p className='font-bold xl:text-[32px] text-[24px] text-[#002e6d]'>What we do</p>
        </div>
        <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 md:grid-cols-3 gap-5 pb-10 pt-[2%]'>
            <div className='about_shadow'>
                <div className='p-5'>
                    <p className='font-semibold text-[20px]'>Access to capital</p>
               

                    <p className='pt-5'>
                    SBA provides an array of financing for small businesses. Options range from the smallest needs in microlending to debt and equity investment capital.
                     </p>
                </div>
            </div>
            <div className='about_shadow'>
                <div className='p-5'>
                    <p className='font-semibold text-[20px]'>Access to capital</p>
               

                    <p className='pt-5'>
                    SBA provides an array of financing for small businesses. Options range from the smallest needs in microlending to debt and equity investment capital.
                     </p>
                </div>
            </div>
            <div className='about_shadow'>
                <div className='p-5'>
                    <p className='font-semibold text-[20px]'>Access to capital</p>
               

                    <p className='pt-5'>
                    SBA provides an array of financing for small businesses. Options range from the smallest needs in microlending to debt and equity investment capital.
                     </p>
                </div>
            </div>
        </div>
    </div>

    <div className='mx-auto overflow-hidden md:max-w-7xl mt-5' id='Review'>
        <div className='ml-5'>
          <p className='font-semibold xl:text-[30px] text-[25px] text-center'>Our Top Testimonies</p>
        </div>
        <div className='mt-5 mb-10'>
          <Reviews/>
        </div>
      </div>

    <div>
        <Footer/>
    </div>

    
    
    </>
  )
}

export default About