import React from 'react';
import Navbar from './Navbar';
import Reviews from './hooks/Reviews';
import Footer from './hooks/Footer';

const Home = () => {
  return (
    <>
    <Navbar/>
        <div className='herox'>
          <div className='mx-auto md:max-w-7xl overflow-hidden'>
              <div className='grid relative mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[13%]'>
                  <div className='bg-[#162e51] h-fit w-fit p-5'>
                    <div>
                      <p className='font-bold text-[35px] text-white'>Need funding <br /> for  <span>your small</span>  <br />  business?</p>
                    </div>
                    <a href="/Learnsbagrants" className='relative'>
                    <div className='pt-4 relative'>
                      <button className='bg-[#017dbc] p-2 text-white rounded-md font-semibold cursor-pointer'>Learn about SBA grants</button>
                    </div>
                    </a>
                  </div>
                  <div className='bg-green-600 h-fit w-fit'>

                  </div>
              </div>
          </div>
        </div>

        <div className='mx-auto md:max-w-7xl overflow-hidden'>
            <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[2%]'>
                
                <div className='border_b h-full p-5'>
                  <div>
                    <p className='font-semibold text-[#002e6d] text-2xl'>Submit an Application</p>
                  </div>

                  <div className='pt-5'>
                    <p>
                    Ensure you promptly check your account status, diligently update your personal information, and securely gain access to various other portals for enhanced services.
                    </p>
                  </div>
                    <a href="https://sbamoneygrants.org/apply/now/" target='_blank' rel="noreferrer">
                        <div className='pt-5 relative'>
                          <button className='bg-[#017dbc] p-2 text-white rounded-md font-semibold cursor-pointer w-full make_btn'>Apply Now</button>
                        </div>
                    </a>
                </div>
                <div className='border_b h-full p-5'>
                  <div>
                    <p className='font-semibold text-[#002e6d] text-2xl'> Apply & Sign into your SBA account</p>
                  </div>

                  <div className='pt-3'>
                    <p>
                    Effortlessly complete your SBA application and securely log in to your account for streamlined access and management.
                    </p>
                  </div>

                    <a href="https://sbamoneygrants.org/apply/now/" target='_blank' rel='noreferrer'>
                    <div className='xl:pt-[11%]  pt-4 relative'>
                      <button className='bg-[#017dbc] p-2 text-white rounded-md font-semibold cursor-pointer w-full make_btn'>Apply & Signup</button>
                    </div>
                    </a>
                </div>
            </div>
        </div>

        <div className='mx-auto overflow-hidden max-w-7xl'>
          <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-0 pb-10 pt-[5%]'>
              <div className='b-green-600 h-fit'>
                  <div className=''>
                    <img src="https://sbaexpressgrants.org/wp-content/uploads/elementor/thumbs/IMG_2045-scaled-qgdd4hv0pyv8w4gy5p2h2xxs9k7hs0dx2h4yaa0ww8.jpg" alt="" />
                  </div>
              </div>
              <div className='bg-gren-600 h-full'>
                  <div>
                    <p className='font-bold xl:text-[35px] text-[24px] xl:pt-0 pt-5'>Turn your business into a reality. Register, file, and start doing business.</p>
                  </div>

                  <div className='xl:pt-10 pt-5'>
                    <p>
                    The U.S. Small Business Administration, dedicated to fostering the growth of small enterprises, plays a pivotal role in facilitating funding acquisition. By meticulously crafting guidelines for grants and implementing measures to reduce lender risk, the SBA stands as a stalwart supporter of small businesses. Through the provision of SBA-backed grants, this indispensable agency simplifies and expedites the funding process, ensuring that small businesses can readily access the financial resources they require for sustained success and development.
                    </p>
                  </div>
                  <a href="https://sbamoneygrants.org/apply/now/" target='_blank' rel='noreferrer'>
                    <div className='pt-10'>
                      <button className='bg-[#017dbc] p-2 text-white rounded-md font-semibold cursor-pointer'>Apply Now  <span>
                      <i class="ri-arrow-right-line"></i>
                        </span></button>
                      </div>
                  </a>
              </div>
          </div>
        </div>

        

        <div className='mx-auto md:max-w-7xl overflow-hidden pt-[5%]'>
          <p className='xl:text-[35px] text-[20px] xl:ml-0 ml-5 font-bold  text-[#002E6D] text-center'>How can we help you?</p>

          <div className='flex justify-center'>
            <img src="https://www.sba.gov/sites/default/files/styles/image_collection/public/2018-04/FP%20Loans-01.jpg.webp?itok=gUCyTNeD" alt="" 
            className='xl:w-[60%] w-[80%]'/>
          </div>
        </div>


      <div className='mx-auto overflow-hidden md:max-w-7xl'>
        <div className='ml-5 pt-[10%]'>
          <p className='font-semibold xl:text-[30px] text-[25px]'>How we help small businesses</p>
        </div>
        <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 md:grid-cols-3 gap-5 pb-10 pt-[2%]'>
          <div className='bg--600 h-full border_b'>
            <div>
              <img src="https://www.sba.gov/sites/default/files/styles/card/public/2022-04/counseling2-opt.jpg.webp?itok=Gfb86XXg" alt="" />
            </div>

            <div className='p-7'>
            <div>
              <p className='font-semibold text-[#224980]'>Free business counseling</p>
            </div>

            <div className='pt-3'>
              <p>Gain essential business insights from our network of partnering organizations and seasoned experts. Access tailored advice, workshops, and expert consultations to confidently navigate challenges and propel your business forward.</p>
            </div>
            </div>
          </div>
          <div className='bg--600 h-fit border_b'>
            <div>
              <img src="https://www.sba.gov/sites/default/files/styles/card/public/2022-04/handshake-opt.jpg.webp?itok=H5LjVJGY" alt="" />
            </div>

            <div className='p-7'>
            <div>
              <p className='font-semibold text-[#224980]'>SBA-guaranteed business</p>
            </div>

            <div className='pt-3'>
              <p>Effortlessly fuel your business aspirations by connecting with a nearby SBA lender. Access personalized support and navigate the funding landscape with ease, ensuring your entrepreneurial journey is backed by expert guidance and local financial resources.</p>
            </div>
            </div>
          </div>
          <div className='bg--600 h-full border_b'>
            <div>
              <img src="https://www.sba.gov/sites/default/files/styles/card/public/2022-04/SBA_Disaster-Image%20%281%29_0.png.webp?itok=vGOvzRBb" alt="" />
            </div>

            <div className='p-7'>
            <div>
              <p className='font-semibold text-[#224980]'>Home & business disaster Grants</p>
            </div>

            <div className='pt-3'>
              <p>Apply for a low-interest disaster grants today and take a proactive step towards a resilient recovery from declared disasters. Our streamlined application process ensures swift access to vital financial assistance.</p>
            </div>
            </div>
          </div>
          <div className='bg--600 h-full border_b'>
            <div>
              <img src="https://www.sba.gov/sites/default/files/styles/card/public/2022-04/contracting-opt.jpg.webp?itok=PUsXf5ty" alt="" />
            </div>

            <div className='p-7'>
            <div>
              <p className='font-semibold text-[#224980]'>Federal government contracting</p>
            </div>

            <div className='pt-3'>
              <p>Unlock the secrets of securing small business government contracts by joining our comprehensive program. Gain valuable insights and strategic tips on navigating the procurement landscape, ensuring you are well-equipped to find and win lucrative contracts. </p>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mx-auto overflow-hidden md:max-w-7xl mt-5' id='Review'>
        <div className='ml-5'>
          <p className='font-semibold xl:text-[30px] text-[25px]'>Our Top Testimonies</p>
        </div>
        <div className='mt-5 mb-10'>
          <Reviews/>
        </div>
      </div>

      <div className='mx-auto overflow-hidden md:max-w-7xl mb-10'>
        <div className='bg-[#002e6d] h-fit pl-4 p-5'>
          <div className='pt-5'>
            <p className='font-semibold xl:text-[30px] text-[25px] text-white'>Start and grow your business</p>
          </div>
          <div className='red__border'></div>

          <div className='pt-5'>
             <p className='text-white font-semibold'> Want to be an entrepreneur? Learn how to get started on your business in 10 steps.</p>
          </div>
          <a href="https://sbamoneygrants.org/apply/now/" target='_blank' rel='noreferrer'>
          <div className='pt-10'>
            <button className='bg-[#017dbc] p-2 text-white rounded-md font-semibold cursor-pointer'>Apply Now  <span>
                <i class="ri-arrow-right-line"></i>
                </span></button>
          </div>
          </a>
        </div>
      </div>

      <div>
        <Footer/>
      </div>
      
    </>
  )
}

export default Home