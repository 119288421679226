import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; 
import 'swiper/css/navigation'; 
import 'swiper/css/pagination'; 
import 'swiper/css/scrollbar'; 
import 'swiper/css/a11y'; 
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';



const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));
  
const YourComponent = () => {
  return (
    <Swiper
    modules={[Navigation, Pagination, Scrollbar, A11y]}
    spaceBetween={20}
    slidesPerView={1} // Show one slide on all mobile devices
    slidesPerGroup={1}
    breakpoints={{
      // When window width is >= 640px
      640: {
        slidesPerView: 2, // Show two slides on smaller desktop screens
        slidesPerGroup: 2,
      },
      671: {
        slidesPerView: 1, // Show one slide on medium screens (md)
        slidesPerGroup: 1,
      },
      // When window width is >= 1024px (desktop lg)
      1024: {
        slidesPerView: 3, // Show three slides on desktop (lg)
        slidesPerGroup: 3,
      },
    }}
    navigation
    pagination={{ clickable: true }}
    scrollbar={{ draggable: true }}
    >
        <SwiperSlide className='rev__shadow py-5'>
            <div className='flex justify-center'>
                <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                >
                        <Avatar alt="Remy Sharp" src="https://photos.spareroom.com/images/flatshare/listings/large/20/71/207164620.jpg" 
                        style={{ width: "50px", height: "50px" }}/>
                </StyledBadge>
            </div>
            <div className='flex justify-center'>
                <p className='font-bold '>Mary Sampton</p>
            </div>

            <div className='mx-3 pt-3'>
                <p  className='text-center'>
                    Thanks to the SBA, my automotive repair shop received a $50,000 grant that allowed us to invest in advanced diagnostic equipment and expand our service offerings. The SBA's financial support and business expertise were pivotal in navigating challenges. This grant not only boosted our efficiency but also positioned us as a leading service provider in the community.
                </p>
            </div>
            <div className='text-center pt-5'>
                <p className='font-semibold text-[#224980]'>
                <i class="ri-map-pin-line"></i>Tallahassee, Florida 🇺🇸 
                </p>
            </div>
            <div className='pt-1 text-center'>
                <p className='text-gray-600'>3 days Ago</p>
            </div>
        </SwiperSlide>

        <SwiperSlide className='rev__shadow py-7'>
            <div className='flex justify-center'>
                <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                >
                        <Avatar alt="Remy Sharp" src="https://www.siouxlandproud.com/wp-content/uploads/sites/68/2019/09/3.jpg?w=1280" 
                        style={{ width: "50px", height: "50px" }}/>
                </StyledBadge>
            </div>
            <div className='flex justify-center'>
                <p className='font-bold '>Sara Kalke</p>
            </div>

            <div className='mx-3 pt-3'>
                <p  className='text-center'>
                The SBA's $30,000 grant transformed my dream of creating eco-friendly products into reality. This funding enabled me to develop sustainable packaging and enhance our production processes. The SBA's commitment to environmentally conscious businesses aligns perfectly with our values, and their support has been a catalyst for our success.
                </p>
            </div>
            <div className='text-center pt-5'>
                <p className='font-semibold text-[#224980]'>
                <i class="ri-map-pin-line"></i>Columbus, Ohio 🇺🇸 
                </p>
            </div>
            <div className='pt-1 text-center'>
                <p className='text-gray-600'>1 week Ago</p>
            </div>
        </SwiperSlide>
      <SwiperSlide className='rev__shadow py-7'>
            <div className='flex justify-center'>
                <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                >
                        <Avatar alt="Remy Sharp" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1CmGZ66ayoDQVJ5bNyrXkufLw0_aAwiec3Ksp-xD2x1HAYkSkX6tgcatNW3Q3_9T5CZU&usqp=CAU" 
                        style={{ width: "50px", height: "50px" }}/>
                </StyledBadge>
            </div>
            <div className='flex justify-center'>
                <p className='font-bold '>Elias Tony</p>
            </div>

            <div className='mx-3 pt-3'>
                <p  className='text-center'>
                Securing a $75,000 grant from the SBA was a turning point for my technology startup. The funds allowed us to hire skilled developers, accelerate product development, and conduct targeted marketing campaigns. The SBA's ongoing mentorship has been invaluable, guiding us through growth challenges and positioning us for long-term success.
                </p>
            </div>
            <div className='text-center pt-5'>
                <p className='font-semibold text-[#224980]'>
                <i class="ri-map-pin-line"></i>Alpharetta, Atlanta  🇺🇸 
                </p>
            </div>
            <div className='pt-1 text-center'>
                <p className='text-gray-600'>6 days Ago</p>
            </div>
        </SwiperSlide>
        <SwiperSlide className='rev__shadow py-7'>
            <div className='flex justify-center'>
                <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                >
                        <Avatar alt="Remy Sharp" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_GoxaGVoopXz9KVqE3WWKWEbHY-EPIdsy1ecr4lTB15FQfgd5u1zUCHfjnAp2vp5K4jo&usqp=CAU" 
                        style={{ width: "50px", height: "50px" }}/>
                </StyledBadge>
            </div>
            <div className='flex justify-center'>
                <p className='font-bold '>Dashill Mercer</p>
            </div>

            <div className='mx-3 pt-3'>
                <p  className='text-center'>
                The SBA's $89,000 grant was a lifeline for my event planning business during a challenging period. With their financial support, I could adapt my services, invest in virtual event capabilities, and stay connected with clients. The SBA's responsiveness and commitment to small businesses make them a true partner in our journey.
                </p>
            </div>
            <div className='text-center pt-5'>
                <p className='font-semibold text-[#224980]'>
                <i class="ri-map-pin-line"></i>Minneapolis, Minnesota 🇺🇸 
                </p>
            </div>
            <div className='pt-1 text-center'>
                <p className='text-gray-600'>4 weeks Ago</p>
            </div>
        </SwiperSlide>
        <SwiperSlide className='rev__shadow py-5'>
            <div className='flex justify-center'>
                <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                >
                        <Avatar alt="Remy Sharp" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2Nk1EGPKXoxGEcwbJa9qJeUPzgIo4pInN6Xh05Tm9VoJjGtT65bVt2EerhSfekMgX2Nw&usqp=CAU" 
                        style={{ width: "50px", height: "50px" }}/>
                </StyledBadge>
            </div>
            <div className='flex justify-center'>
                <p className='font-bold '>Orson Winthrop</p>
            </div>

            <div className='mx-3 pt-3'>
                <p  className='text-center'>
                The SBA played a crucial role in the success of my IT consulting firm. Their counseling services helped me navigate the complexities of starting a business. The SBA-backed Grant provided the initial capital needed for office space and technology infrastructure. Without their support, achieving this level of growth in such a competitive industry would have been nearly impossible.
                </p>
            </div>
            <div className='text-center pt-5'>
                <p className='font-semibold text-[#224980]'>
                <i class="ri-map-pin-line"></i>Milwaukee, Wisconsin 🇺🇸 
                </p>
            </div>
            <div className='pt-1 text-center'>
                <p className='text-gray-600'>1 day Ago</p>
            </div>
        </SwiperSlide>
        <SwiperSlide className='rev__shadow py-5'>
            <div className='flex justify-center'>
                <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                >
                        <Avatar alt="Remy Sharp" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaznxn7iQtimmbBPQtIitfTpdB59iqiSwDDGdSMJA3J5VXmxkrq-JlG4ls0juxvlg9P4Y&usqp=CAU" 
                        style={{ width: "50px", height: "50px" }}/>
                </StyledBadge>
            </div>
            <div className='flex justify-center'>
                <p className='font-bold '>Quinn Montgomery</p>
            </div>

            <div className='mx-3 pt-3'>
                <p  className='text-center'>
                The SBA's contracting assistance program transformed my construction company. With their guidance, we secured a substantial $100,000 grant, enabling us to expand operations and undertake larger projects. This funding not only boosted our business but also empowered our community, thanks to the SBA's commitment to supporting minority-owned enterprises. Grateful for their profound impact.
                </p> 
            </div>
            <div className='text-center pt-5'>
                <p className='font-semibold text-[#224980]'>
                <i class="ri-map-pin-line"></i>Fort Wayne, Indiana 🇺🇸 
                </p>
            </div>
            <div className='pt-1 text-center'>
                <p className='text-gray-600'>2 weeks Ago</p>
            </div>
        </SwiperSlide>
     
    </Swiper>
  );
};

export default YourComponent;
